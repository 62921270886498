import { defineComponent } from '@vue/composition-api';
import { useResendInvoiceMutation } from '@/generated-types/graphql.types';
import { useResendTimer } from '@/util/hooks';
import { timeZero } from '@/util/filters';
export default defineComponent({
    props: {
        data: {
            type: Object,
            default: () => null
        },
        mobile: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root } = context;
        const { timer, startTimer } = useResendTimer(root);
        const { mutate } = useResendInvoiceMutation({});
        const resendInvoice = () => {
            mutate({ invoice_id: props.data.invoice_id }, {});
            startTimer();
        };
        return {
            timer,
            resendInvoice,
            timeZero
        };
    }
});
