import { defineComponent, computed, reactive } from '@vue/composition-api';
import { PayoutStatus } from '@/generated-types/graphql.types';
import Label from '@/uikit/Label.vue';
import orderBy from 'lodash/orderBy';
import ResendInvoiceButton from '@/Finance/components/ResendInvoiceButton.vue';
import DataTable from '@/components/DataTable.vue';
import i18n from '@/i18n/i18n-setup';
const tableCSS = {
    table: 'table table-hover table-center',
    theadTh: 'header-item',
    thWrapper: 'th-wrapper',
    arrowsWrapper: 'arrows-wrapper',
    arrowUp: 'arrow up',
    arrowDown: 'arrow down',
    footer: 'footer',
    notFoundTd: 'not-found'
};
const statusColors = {
    [PayoutStatus.Completed]: 'green',
    [PayoutStatus.DuplicatedRequest]: 'blue',
    [PayoutStatus.Pending]: 'yellow',
    [PayoutStatus.Failed]: 'red'
};
const excludedMobileColumns = ['host', 'created', 'ts_created', 'actions'];
export default defineComponent({
    components: {
        DataTable,
        Label,
        ResendInvoiceButton
    },
    props: {
        columns: {
            type: Array,
            default: () => []
        },
        value: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const sortState = reactive({ direction: 'asc', field: null });
        const data = computed(() => {
            if (sortState.field && sortState.direction) {
                return orderBy(props.value, [sortState.field], [sortState.direction]);
            }
            return props.value;
        });
        const onUpdate = ({ sortField, sort }) => {
            sortState.direction = sort;
            sortState.field = sortField;
        };
        const host = computed(() => props.columns?.find(item => item.name === 'host'));
        const mobileColumns = computed(() => props.columns?.filter(item => !excludedMobileColumns.includes(item.name)));
        const notFoundText = computed(() => i18n.t('finance.history.table.not_found'));
        return {
            data,
            host,
            mobileColumns,
            notFoundText,
            sort: 'asc',
            sortField: 'created',
            statusColors,
            tableCSS,
            onUpdate
        };
    }
});
